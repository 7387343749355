import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';

const NikkeGuidesEmilia: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Emilia guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_emi.webp"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Emilia guide & review</h1>
          <h2>A guide & review for Emilia in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>24/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Banner" />
        <StaticImage
          src="../../../images/nikke/generic/banner_emi.webp"
          alt="Emilia"
        />
        <p>
          <strong>Emilia</strong> banner will be available:{' '}
          <strong>3/21 (after maintenance) ~ 4/11 04:59 (UTC+9) </strong>
        </p>
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="emilia" enablePopover />
        </div>
        <p>
          An unexpected encounter that bridges two different universes, Emilia
          opens the curtain to an amusing crossover between a post-apocalyptic
          wasteland and a fantasy realm. Emilia wields a staff, a rocket
          launcher replica with extended range and freezing power. She is
          outfitted with a special mechanic that amplifies her Charge Damage
          relative to her ammo capacity, but even that is not as spicy as her
          Burst Skill that invokes a powerful slow-acting spell that sweeps over
          an enormous area, dealing a significant amount of instantaneous
          damage. Her kit may seem dull at first glance, but her true
          performance shall be revealed in the upcoming raids.
        </p>
        <SectionHeader title="Kit Analysis" />
        <ul>
          <li>
            Unlike most other RLs, and identical to A2's, Emilia's weapon has{' '}
            <strong>a default explosion range of 750</strong> — 1.5x larger than
            the default 500. This allows her to hit 3 enemies simultaneously in
            Arena, inflating her natural burst regen. With Burst Skill, this can
            further be expanded to 1500, with which she can strike all opposing
            Nikke(s) at the same time!
          </li>
          <li>
            Emilia's wider range helps her hit more parts or mobs at once,
            potentially doubling or tripling her potential damage.
          </li>
          <li>
            Compared to A2, Emilia's buffs are consistent and will not decrease
            whether there is a part present or not. Meanwhile, A2's Part Damage
            will ultimately become useless after all parts are destroyed. Hence,
            she can be considered an upgrade to our NieR comrade.
          </li>
          <li>
            Emilia's kit is also packed with so many enhancements that improve
            her damage multiplier(s) but ATK. While this is horrendous in some
            cases, especially with major CP deficits and stat penalty in
            campaign, it also means Emilia scales extremely well with ATK buffs
            from her allies. Thus, the gap in her output when supported versus
            unsupported can be enormous!
          </li>
        </ul>
        <h6>Skill 1</h6>
        <blockquote>
          <p>■ Activates when attacking with Full Charge. Affects self.</p>
          <p>Charge Speed ▲ 13.01% for 1 round(s).</p>
          <p>
            Charge Damage ▲ 2.01% for every unit in the final Max Ammunition
            Capacity.
          </p>
          <p>Lasts for 1 round(s).</p>
        </blockquote>
        <ul>
          <li>
            Whenever she releases a fully charged attack, for the next shot, she
            obtains bonus Charge Speed and Charge Damage proportional to her max
            ammo capacity.
          </li>
          <li>
            At Level 10, she gains a maximum of ~13% Charge Speed, which
            translates to <strong>around 10.6% damage increase</strong> as long
            as she keeps delivering fully charged shots. The subtraction is due
            to the animation in between shots particularly on AUTO.
          </li>
          <li>
            She also gains a Charge Damage enhancement equal to{' '}
            <strong>~2% multiplied by her max ammo count (capped at 50)</strong>
            .
          </li>
          <li>
            This may seem trivial, but to a trained pair of eyes, one would
            realize this could become massive in the right situation. It is true
            that, with her base charge damage at 250%, an increase of 2% amounts
            to just 0.8% TDM (Total Damage Multiplier).
          </li>
          <li>
            However, this value is <strong>hardly</strong> diluted, since Charge
            Damage buffs are uncommon (let's just ignore her Burst Skill).
            Should you provide Emilia with a stack of Max Ammo OL and
            appropriate buffs from other allies, this buff will quickly become
            apparent.
          </li>
        </ul>
        <h6>Skill 2</h6>
        <blockquote>
          <p>■ Activates when attacking with Full Charge. Affect target(s).</p>
          <p>
            Deals Fixed Damage to the main body equal to 58.99% of the damage
            dealt by self.
          </p>
          <p>■ Activates when entering Full Burst. Affects self.</p>
          <p>Max Ammunition Capacity ▲ 3 round(s) for 10 sec.</p>
        </blockquote>
        <ul>
          <li>
            A true classic. Treat her second ability as pure TDM (Total Damage
            Multiplier). Any damage she deals is multiplied by the percentage
            shown in the first component.
          </li>
          <li>
            It's also permanent, assuming she constantly delivers fully charged
            shots.
          </li>
          <li>
            In total, at Level 10, she can enjoy a ~59% TDM. This is way more
            than what her other skills provide and should be maxed ASAP.
          </li>
          <li>
            When entering Full Burst, she also raises her Max Ammo capacity,
            momentarily enhancing the effects of Skill 1.
          </li>
        </ul>
        <Alert variant="primary">
          <p>
            Since the damage enhancement does not belong to any typical buff
            categories, it also scales well with other common buffs because
            there is no dilution involved. This makes Emilia one of the easiest
            units to support!
          </p>
        </Alert>
        <h6>Burst</h6>
        <blockquote>
          <p> ■ Affects self.</p>
          <p>Explosion Range ▲ 101.24% for 10 sec.</p>
          <p>Freezing Witch</p>
          <p>
            Function: Decreases Charge Speed and increases Charge Damage for 1
            shot(s).
          </p>
          <p>Effect 1: Charge Speed ▼ 300%.</p>
          <p>Effect 2: Charge Damage ▲ 1300.53%</p>
        </blockquote>
        <ul>
          <li>
            'Extends' her charge time considerably to increase charge damage for
            one shot.
          </li>
          <li>The default delay is +3s, uniform across all levels.</li>
          <li>
            The maximum boost is ~1300% Charge Damage, equal to{' '}
            <strong>~5.2x of normal attack damage.</strong>
          </li>
          <li>
            Burst Skill attack does <strong>NOT</strong> activate S1!
          </li>
          <li>
            In AUTO, you can perform 3 Full Charge attacks in place of this
            enhanced blast, which means the maximum damage difference is
            (1+~5.2x)/3 = <strong>~2.067 shots.</strong>
          </li>
          <li>
            However, you will lose the explosion range buff, which scales per
            level and caps at ~101%. This in turn elevates her radius to around
            1500, enough to hit all enemies in PvP simultaneously, akin to A2's
            range. Useful against distant parts and mobs.
          </li>
          <li>Can overkill parts if timed correctly.</li>
        </ul>
        <Alert variant="primary">
          <p>
            The damage difference between using Burst Skill and not is not that
            significant unless it is necessary for her to utilize that explosion
            range buff to hit parts that are more distant. Otherwise, if other
            units offer more powerful Burst Skill, prioritize theirs first.
          </p>
        </Alert>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Usage" />
        <h6>Campaign</h6>
        <p>
          Her performance in campaign can be considered to be similar to 3
          units: <NikkeCharacter mode="inline" slug="a2" enablePopover />{' '}
          <NikkeCharacter mode="inline" slug="scarlet" enablePopover />, and
          surprisingly{' '}
          <NikkeCharacter mode="inline" slug="centi" enablePopover />.
        </p>
        <p>
          Emilia has the same splash radius as A2, and she can deal a ton of
          damage to clustered mobs from her shots during Burst. This is where
          the comparison to Scarlet stems from. Regardless, there is a
          significant difference in her playstyle. While Scarlet's nuke is
          instant, Emilia takes 4 seconds to charge her nuke. And while
          Scarlet's nuke can hit everyone on the screen, Emilia cannot. She can
          only hit the enemies that are within the Blast Radius of her nuke.
          It's worth mentioning that her damage outside of Burst, while worse
          than Scarlet, is pretty satisfactory due to her Skill 2's TDM.
          Finally, the comparison to Centi can be made through the simple fact
          that you can spam Emilia's low-charge shots on buildings to rapidly
          accrue high amounts of energy, due to her higher splash radius.
        </p>
        <p>
          Overall, she is a solid situational unit to use for campaign, and can
          be used as a Main B3. However, she cannot compete with the likes of
          Scarlet in full-screen clearing capability or SBS/SnowWhite in boss
          nuking capacity. She is a jack-of-all-trades but master of none.
        </p>
        <Alert variant="primary">
          <p>
            As Emilia's DPS is not dependent on Burst Skill that much, her
            damage is pretty stable, which is always useful in campaign for
            mopping breadcrumbs. This is not an asset that many meta characters
            have, i.e. Alice, Red Hood, and SBS. It is also the reason Modernia
            and Scarlet are preferred in some stages. These two units are still
            better, though, because they have inherent ATK buffs. Furthermore,
            Emilia's splash damage is uniform, unlike SBS's distributed one,
            which means she is exempt from the "too much splitting" curse. She
            also does not harm herself like A2 or Scarlet and will not randomly
            lose buffs for the same reason.
          </p>
        </Alert>
        <h6>Raids</h6>
        <p>
          While she may not be particularly useful for Union Raids, she will
          definitely hold her ground against Solo Raid bosses. The second best
          Rocket Launcher unit to be released yet powercreeps A2. Not only that,
          she is definitely the second best water element DPS as well (after
          xLudmilla)! Emilia will do surprisingly well against Bosses with at
          least 2 parts, which is not uncommon in Nikke, and will be able to
          compete with the likes of Modernia! Her favourite meal will be in the
          form of a fiery dragon, Nihilister, which sports 4 parts. Wielding the
          element of PSID, she is the ultimate counter to Nihilister!
        </p>
        <h6>Simulation Overclock</h6>
        <p>
          There are not many Water DPS in Nikke. After xLudmilla, Emilia will
          definitely be the B3 of choice in case we ever need to fulfill the
          requirement of raptures being weak to water element.
        </p>
        <h6>PVP</h6>
        <p>
          If you haven't heard enough praise, it is time to talk about the game
          mode that Emilia will shine like the star she is! Emilia has a base
          burst gen of 1.4%, a splash radius of 750, and a charge time of 1
          second with no animation hold like A2 has. In simple words, firstly,
          her Splash Radius means that she will be hitting 3 people, hence
          pressuring P1+P2 and P3! Secondly, all of those numbers we told you
          above make her the 4th most powerful battery in the game!
        </p>
        <Alert variant="primary">
          <p>
            If you want the equation, Emilia hits 3 Nikke(s) * 2 ticks each
            because of Skill 2, plus 3 covers, resulting in 9 total procs. 9 *
            1.4% = 12.6% burst generation per hit.
          </p>
        </Alert>
        <p>
          This means that even if you don't slot her in as a Burst 3, she will
          act a passive battery for your PvP team! For PvP Players, this is
          basically a Clip RL in disguise and a superb asset to have. If this
          wasn't sweet enough, Emilia seems to have the Biggest Base AoE Nuke
          out of everyone in PvP! Even higher than Scarlet!
        </p>
        <p>
          The drawback is quite clear here, that it takes 4 seconds to charge.
          But even this drawback finds a use! Usually, Noah, with her 3s of
          Invincibility, can be used to block the AoE nuke of Nikkes like
          Scarlet, Maiden, 2B, and many more, assuming teams are of similar
          burst gen speeds. Emilia, on the other hand, takes 4 seconds to charge
          her nuke, which means that she can wait out the invincibility
          timeframe, then hit the enemy team as soon as it drops!
        </p>
        <p>
          We can assure you with full confidence that Emilia is one of the
          strongest attackers you can probably use in PvP. Just make sure to
          give her enough survivability if you burst with her. We can probably
          talk more about her use in PvP, but we won't do that here. We hope you
          look forward to our Half Anniversary Update of the PvP Guide!
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          Like any other DPS, you would want to have Elemental Damage and ATK
          buffs for Emilia. Neither of them are diluted and are amazing buffs on
          her. As for any other rolls, she performs well with charge speed and
          max ammo:
        </p>
        <p>
          For PvP, her major target priority will be a minimum of 2 Charge Speed
          rolls.
        </p>
        <Alert variant="primary">
          <p>
            8%+ Charge Speed total from OL in tandem with S1 Level 10 will make
            her shoot 3 shots in 2.5RL speed in PvP. It also helps you shoot
            considerably more shots in 3 minutes in PvE as well. Similarly,
            while it is super expensive and practically impossible to reach,
            24.7+% charge speed total from OL together with S1 Level 10 allows
            Emilia to shoot 3 shots in 2RL speed in PvP!
          </p>
        </Alert>
        <p>
          Emilia also appreciates Max Ammo Capacity rolls, since they work well
          with her Skill 1, but they work similarly to Charge Speed or Charge
          Damage lines. Overall, we recommend having 1-2 Ammo on her because it
          also concerns her uptime.
        </p>
        <Alert variant="primary">
          <p>
            Four Max Ammo OL each providing a boost of 4 ammo sums up to an
            aggregate of 12.8% increased damage output (relative to 250%). This
            is a nice uplift, but do note that she also benefits more or less
            the same from Charge Damage or Charge Speed lines (which can offer
            higher values more easily). With Max Ammo, however, her uptime will
            further be extended.
          </p>
        </Alert>
        <h5>Skill Investments</h5>
        <ul>
          <li>
            <strong>Skill 1: 4-7</strong>
          </li>
          <ul>
            <li>
              While not as major of a buff as Skill 2, it works well to boost
              Emilia's damage. Barely diluted. We recommend keeping it at 4 and
              only upgrading it to 7 if you have extra materials that you
              haven't planned on using yet. For PvP lovers, this skill can be
              maxed out to level 10 for the extra charge speed.
            </li>
            <li className="highlight">
              <strong>
                This skill is more efficient the more Max Ammo OLs Emilia has.
                Real value wise, at 20 base ammo, every level yields a maximum
                of ~0.9% TDM, but this will gradually decrease as the level goes
                up.
              </strong>
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <strong>Skill 2: 7-10</strong>
          </li>
          <ul>
            <li>
              Skill 2 is straight up a TDM. Breakpoints at Level 4 (Max Ammo +2)
              and 7 (Max Ammo +3). Upgrade it to 7, despite it having poor
              scaling (1.43x). If you don't have many high priority skills to
              work on, this skill should eventually be maxed out to 10.
            </li>
            <li className="highlight">
              <strong>
                Real value wise, expect a maximum of ~1.4% TDM per level, but
                this will decrease gradually as the level goes up. An extra of
                around ~0.6% is provided at Level 4 and 7, at maximum level of
                Skill 1 (less if diluted by having more ammo).
              </strong>
            </li>
          </ul>
          <li>
            <strong>Burst: 7-10</strong>
          </li>
          <ul>
            <li>
              Level 7 is the bare minimum Emilia needs to hit 5 people in PvP
              with her Nuke during burst. It will also allow you to hit all
              parts of a Boss with multiple parts, like Nihilister, if you hit
              the Boss in the middle. Upgrade it to 7 first. It is up to you
              whether you want to increase it to 10, but do know that Rem,
              according to our initial analysis, will most definitely want Level
              10 Burst Skill too.
            </li>
          </ul>
        </ul>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_4_sm.png"
          alt="Guide"
        />
        <p>
          In PvE, the primary cubes are always Bastion and Resilience because
          they provide the highest uptime bonus. Since Emilia also scales with
          Max Ammo, you can also consider Wingman if highly leveled, which will
          make her S1 more effective.
        </p>
        <p>
          In PvP, you can additionally consider Vigor for increased survival,
          Adjutant if that helps with reaching the breakpoint informed in Gear
          Investments section, or Quantum for bonus burst gen.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="PVE Team Compositions" />
        <h5>Team #1: Basic Duo Teams</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="tia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="emilia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>Or</p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="emilia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">Flex</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Liter JKs or Liter Bunnies, both are decent comps where Emilia can
          easily fit in to. You can expect her to perform well in Campaign and
          Bosses in these teams. Liter and Naga/Noir provide ATK buffs that
          Emilia lacks, whereas Tia and Blanc provide separate damage
          multipliers that can boost her damage substantially! Naga and Blanc
          also provides heals in case she needs them. One thing to note about
          the Bunnies comp is their inherent lack of burst gen, which is
          remedied by using Emilia as B3!
        </p>
        <h5>Team #2: Rem Synergy</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rem" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="emilia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">Flex</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          Rem is not out yet, but her skills are! We believe that with heals and
          Buffs for RLs that Rem provides, Emilia and Rem will be able to make a
          great combo against Bosses, and maybe even Campaign...
        </p>
        <SectionHeader title="PVP Team Compositions" />
        <h5>Team #1: Emilia as Burst Gen Battery</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="emilia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
        </div>
        <p>Or</p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="miracle-fairy-anne"
                enablePopover
              />
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="emilia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Both of these teams replace Anis/Centi for Emilia, who not only
          provides extra damage pressure on P1+P2+P3, but also prevents the
          demotion of Burst Speed Tier. Additionally, a common problem High Sync
          Level Players face is that they struggle to use Anis against opponents
          as it leads to a significant loss in Team CP. Hence, Emilia provides a
          very strong choice as a replacement.{' '}
        </p>
        <h5>Team #2: Emilia as the Main B3</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="emilia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Simple team with one goal in mind: Get Indomitability on Emilia. You
          want Emilia to be the lowest HP unit among the 2 highest attack units
          in your team (who will probably be Emilia and RedHood in this case),
          so that Blanc is able to provide her with Indomitability. Since Emilia
          won't be able to die now, It is simply a matter of time that the
          opponent team falls to their deaths by her hands. Note that, Emilia
          can be countered by a slow Noah (3/4RL+ beyond). The faster you burst
          with Emilia, the more likely she is to be counterable.{' '}
        </p>
        <h5>Team #3: Just Yet Another God Comp</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="emilia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
        </div>
        <p>
          The combos are Noise + Rapunzel (Rookie Arena God Comp) or Biscuit +
          RedHood (Common Stall Team). Nothing much to be said about either
          comp. You all probably already know how tough it is to deal with the
          God Comp. For the other team, Noah + Biscuit hold P1 and attract all
          the damage to themselves, while Emilia charges her nuke and prepares
          to finish her opponents.
        </p>
        <SectionHeader title="Should You Pull" />
        <p>
          Yes, especially if you care about PvP. One copy is enough. Not only is
          she absolutely meta in PvP, we believe her performance will be
          unexpectedly good in Solo Raids as a DPS, especially with Rem. As for
          dupes, if you care about competition in PvP, and especially if you
          want to prepare for Champions Arena, you should definitely consider
          pulling for MLB (or even core 7, if you are a high spender).
        </p>
        <p>
          You might have a question in your mind — what about exchanging Golden
          Mileage for her? Unless you really care about PvP, we discourage such
          interchange. If you want any other reason for using your coupons on
          her, the fact that she is a limited collab character and will not be
          available again could validate this proposition.
        </p>
        <p>
          But remember: coupons are extremely valuable, and you don't want to
          miss out on the upcoming good DPS, which will probably have a bigger
          influence on your account. Half anniversary is only around the corner!
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Excessive abnormal gear in stock (for most players).</li>
                  <li>
                    Fourth highest burst generation in PvP with potential to be
                    first with heavy investments.
                  </li>
                  <li>Scales really well with ATK buffs.</li>
                  <li>
                    Shots can be spammed like Centi toward environmental
                    destructibles for burst gen in Campaign.
                  </li>
                  <li>
                    Highly flexible for PvP; can act as burst gen battery or
                    main Burst 3 unit.
                  </li>
                  <li>Highest base AoE attack nuke for PvP.</li>
                  <li>
                    Auto-friendly, no need to manual her at all unless you need
                    to aim for a specific spot for her nuke, or need extra burst
                    gen.
                  </li>
                  <li>Has the highest range for an RL (equal to A2).</li>
                  <li>
                    Her AoE nuke can bypass Noah's invincibility time frame.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Limited unit.</li>
                  <li>Buffs reliant on Full Charge(s).</li>
                  <li>No innate ATK buff.</li>
                  <li>
                    Her base attack ratio is 61.3%, which is the lowest in RL
                    category, shared with Vesti and some other units.
                  </li>
                  <li>
                    With no Boss Parts, Emilia cannot compete in damage against
                    Meta B3 against Solo Bosses.
                  </li>
                  <li>
                    4-second charge time on AoE attack can be a liability in PvP
                    Defense Teams, and can be hard to execute well in Campaign.
                  </li>
                  <li>
                    In PvP, her fully charged shots can count up to 4-5 hits per
                    shot if she hits 2 Jackal-linked Nikkes, which means she can
                    be highly susceptible to dying against Scarlet's Skill 2.
                  </li>
                  <li>
                    Skill scaling per level is less than 1.69x (default for most
                    skills) on average.
                  </li>
                  <li>
                    As Water Element, if she gets hit by Elemental OL
                    Scarlet/SAnis (Electric) in PVP, she can die very fast.
                  </li>
                  <li>
                    Her burst nuke can actually miss in PvP, due to whiffing or
                    target dies.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Story (High Deficit)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Story (Low Deficit)</p>
          </div>
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesEmilia;

export const Head: React.FC = () => (
  <Seo
    title="Emilia guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Emilia in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
